import "./index.scss";
import "./phoneStyle.scss";
import phone from "../../assets/icons/phone.svg";
import email from "../../assets/icons/email.svg";
import map from "../../assets/icons/map.svg";

const ContactUs = () => {
  return (
    <div className="contact_us_container" id="contact">
      <div className="contact_us_container__data">
        <p className="contact_us_container__data__title typography_h1">
          Get in touch with us.
        </p>

        <div className="contact_us_container__data__contact">
          <img src={phone} alt="Phone Icon" />
          <p className="contact_us_container__data__contact__info typography_content_p3">
            +254 - 714 - 179 - 643
          </p>
        </div>

        <div className="contact_us_container__data__contact">
          <img src={email} alt="Email Icon" />
          <p className="contact_us_container__data__contact__info typography_content_p3">
            investmentbankofkemet@gmail.com
          </p>
        </div>

        <div className="contact_us_container__data__contact">
          <img src={map} alt="Map Icon" />
          <p className="contact_us_container__data__contact__info typography_content_p3">
            B02D, Chelezo H, Kindaruma Road, Nairobi, Kenya,
          </p>
        </div>
      </div>

      <div className="contact_us_container__map">
        <iframe
          title="Map"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=%20Kindaruma%20Rd%20Nairobi,%20Kenya&t=&z=19&ie=UTF8&iwloc=&output=embed"
          scrolling="no"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUs;
