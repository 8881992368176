export default {
  register: {
    loading: false,
    error: null,
  },
};

export type InitialState = {
  register: Record<string, any>;
};
