import "./index.scss";
import "./phoneStyle.scss";
import TextField from "../common/InputForms/TextField";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { InitialState } from "../../redux/initialState";
import registerData from "../../redux/actions/register";
import Loader from "../loader";

const UserInfo = () => {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    investmentAmount: "",
    mobileNumber: "",
    bitcoinAddress: "",
    sponsorMobileNumber: "",
  });

  const [fieldError, setFieldError] = useState("");

  const dispatch = useDispatch();

  const { loading, error } = useSelector(
    ({ register }: InitialState) => register
  );

  const handleChange = ({ target: { name, value } }) => {
    setUserData({ ...userData, [name]: value });
  };

  const validateAndSubmit = () => {
    const {
      firstName,
      lastName,
      email,
      investmentAmount,
      mobileNumber,
      bitcoinAddress,
      sponsorMobileNumber,
    } = userData;

    if (
      firstName &&
      lastName &&
      email &&
      investmentAmount &&
      mobileNumber &&
      bitcoinAddress &&
      sponsorMobileNumber
    ) {
      dispatch(registerData(userData, setUserData, setFieldError));
    } else {
      setFieldError("Register failed: Please fill out the forms");
    }
  };

  const formsBody = () => {
    return (
      <>
        <p className="user_info_container__register_title typography_h1">
          Fill out the forms below to{" "}
          <span className="typography_bold">Register</span>.
        </p>
        {fieldError && (
          <p className="user_info_container__field_error typography_bold typography_error">
            {fieldError}
          </p>
        )}

        {error && (
          <p className="user_info_container__field_error typography_bold typography_error">
            {error}
          </p>
        )}

        <div className="user_info_container__fullname card">
          <p className="user_info_container__fullname__title typography_bold">
            Full Name
          </p>
          <div className="user_info_container__fullname__forms">
            <TextField
              labelText="First Name"
              name="firstName"
              type="text"
              onChange={handleChange}
              value={userData.firstName}
              disabled={loading}
            />
            <TextField
              labelText="Last Name"
              name="lastName"
              type="text"
              onChange={handleChange}
              value={userData.lastName}
              disabled={loading}
            />
          </div>
        </div>
        <TextField
          labelText="Email (Same as PayPal)"
          name="email"
          type="email"
          onChange={handleChange}
          value={userData.email}
          disabled={loading}
        />
        <TextField
          labelText="Investment Amount"
          name="investmentAmount"
          type="text"
          onChange={handleChange}
          value={userData.investmentAmount}
          disabled={loading}
        />
        <TextField
          labelText="Mobile Number"
          name="mobileNumber"
          type="text"
          onChange={handleChange}
          value={userData.mobileNumber}
          disabled={loading}
        />
        <TextField
          labelText="Bitcoin Address"
          name="bitcoinAddress"
          type="text"
          onChange={handleChange}
          value={userData.bitcoinAddress}
          disabled={loading}
        />
        <TextField
          labelText="Sponsor Mobile Number"
          name="sponsorMobileNumber"
          type="text"
          onChange={handleChange}
          value={userData.sponsorMobileNumber}
          disabled={loading}
        />
        <input
          type="submit"
          className="button submit"
          value="Submit"
          onClick={() => validateAndSubmit()}
        />
      </>
    );
  };

  const body = () => {
    if (loading) return <Loader />;
    return formsBody();
  };

  return (
    <div className="user_info_container" id="register">
      {body()}
    </div>
  );
};

export default UserInfo;
