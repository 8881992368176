import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux";

import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBlnSvV3KKaZ8fkUYFM2ikKQ_wOL4gdsdA",
  authDomain: "kemet-97b56.firebaseapp.com",
  projectId: "kemet-97b56",
  storageBucket: "kemet-97b56.appspot.com",
  messagingSenderId: "718148614863",
  appId: "1:718148614863:web:26ed36e0b38b0878b495ba",
};

// Initialize Firebase

initializeApp(firebaseConfig);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
