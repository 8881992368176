import "./index.scss";
import "./phoneStyle.scss";
import DarkBackground from "../common/DarkBackground";

const AboutUs = () => {
  return (
    <div className="about_us_container" id="about">
      <DarkBackground />
      <div className="about">
        <p className="about_us_container__main typography_content_p1">
          About US
        </p>
        <p className="about_us_container__minor typography_content_p3">
          Virtual Crypto and Investment Bank of Kemet Provides you an
          opportunity to benefit from Digital and Fiat Currency
        </p>
      </div>

      <div className="about">
        <p className="about_us_container__main typography_content_p1">
          mission
        </p>
        <p className="about_us_container__minor typography_content_p3">
          Provide an organized, fair, transparent, and efficient market
          forinvesting in Virtual Crypto and Investment Bank of Africa, and
          secure a safe environment for trading shares to deepen trust in
          Virtual Crypto and Investment Bank Technology.
        </p>
      </div>

      <div className="about">
        <p className="about_us_container__main typography_content_p1">vision</p>
        <p className="about_us_container__minor typography_content_p3">
          To be an Advanced Participant in the Financial Markets distinguished
          legislatively and technically, regionally and globally, arising to the
          latest International Standards in the Financial Markets to provide an
          attractive Investment environment through modern technological
          revolution of Virtual Crypto and Investment Bank.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
