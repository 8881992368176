import * as types from "../actionTypes/register";
import * as firebase from "firebase";

const registrationStarted = () => ({
  type: types.REGISTRATION_START,
});
const registrationSuccess = () => ({
  type: types.REGISTRATION_SUCCESS,
});
const registrationError = (error) => ({
  type: types.REGISTRATION_ERROR,
  payload: { error },
});

const registerData = (data, resetUserData, removeError) => async (dispatch) => {
  dispatch(registrationStarted());

  try {
    const d = new Date();

    const db = firebase.firestore();

    data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
    data.year = d.getFullYear();
    data.month = d.getMonth();

    db.collection("registered_users")
      .add(data)
      .then(() => {
        dispatch(registrationSuccess());
        resetUserData({
          firstName: "",
          lastName: "",
          email: "",
          investmentAmount: "",
          mobileNumber: "",
          bitcoinAddress: "",
          sponsorMobileNumber: "",
        });
        removeError("");
      })
      .catch((error) => dispatch(registrationError(error.message)));
  } catch (error) {
    dispatch(registrationError(error.message));
  }
};

export default registerData;
