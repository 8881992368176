import "./index.scss";
import "./phoneStyle.scss";
import { useEffect, useState, useRef } from "react";
import menuIcon from "../../assets/icons/menu.svg";
import appLogo from "../../assets/logo/logo-outline.png";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [header, setHeader] = useState("");
  const [activeHeader, setActiveHeader] = useState("");

  const HEADER_OFFSET = 300;

  const listenScrollEvent = () => {
    const OFFSET = window.scrollY;

    if (OFFSET <= HEADER_OFFSET) {
      setHeader("");
    } else if (OFFSET >= HEADER_OFFSET) {
      setHeader("white_background");
    }

    if (OFFSET >= 500 && OFFSET < 2458) setActiveHeader("Services");
    else if (OFFSET >= 2458 && OFFSET < 3264) setActiveHeader("Register");
    else if (OFFSET >= 3264 && OFFSET < 3922) setActiveHeader("About");
    else if (OFFSET >= 3922) setActiveHeader("Contact");
    else setActiveHeader("Home");
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  const linkToDiv = (link) => {
    const divElement = document.querySelector(link);
    setShowMenu(false);
    divElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "center",
    });
  };

  const setActiveHeaderClass = (header) => {
    if (header === activeHeader) return "active";
    return "";
  };

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setShowMenu]);

  return (
    <div className={`header_container ${header}`} ref={ref}>
      <div className={`hamberger ${showMenu ? "active_nav" : ""}`}>
        <img
          src={menuIcon}
          className={`header_container__menu_icon`}
          alt=""
          onClick={() => setShowMenu(!showMenu)}
        />
        <div className="header_container__logo_container">
          <img src={appLogo} alt="" />
          <p className="header_container__logo_container__logo typography_logo">
            KEMET
          </p>
        </div>
      </div>
      <div className={`header_container__nav ${showMenu ? "show_nav" : ""}`}>
        <p
          onClick={() => linkToDiv("#home")}
          className={`header_container__nav__menu typography_menu ${setActiveHeaderClass(
            "Home"
          )}`}
        >
          Home
        </p>
        <p
          onClick={() => linkToDiv("#services")}
          className={`header_container__nav__menu typography_menu ${setActiveHeaderClass(
            "Services"
          )}`}
        >
          Services
        </p>
        <p
          onClick={() => linkToDiv("#register")}
          className={`header_container__nav__menu typography_menu ${setActiveHeaderClass(
            "Register"
          )}`}
        >
          Register
        </p>
        <p
          onClick={() => linkToDiv("#about")}
          className={`header_container__nav__menu typography_menu ${setActiveHeaderClass(
            "About"
          )}`}
        >
          About
        </p>
        <p
          onClick={() => linkToDiv("#contact")}
          className={`header_container__nav__menu typography_menu ${setActiveHeaderClass(
            "Contact"
          )}`}
        >
          Contact
        </p>
      </div>
    </div>
  );
};

export default Header;
