import "./index.scss";
import "./phoneStyle.scss";
import Header from "../../components/Header";
import UserInfo from "../../components/UserInfo";
import DarkBackground from "../../components/common/DarkBackground";
import AboutUs from "../../components/AboutUs";
import ContactUs from "../../components/ContactUs";
import OurServices from "../../components/OurServices";

const Home = () => {
  return (
    <div className="home_container">
      <Header />

      <div className="home_container__intro" id="home">
        <DarkBackground />
        <p className="home_container__intro__main typography_h1">
          Virtual Crypto and Investment Bank of Kemet
        </p>
        <p className="home_container__intro__minor typography_content_p2">
          Kemet a “Black Land” Africans Civilization where there was no one in
          the world.
        </p>
        <p className="home_container__intro__minor typography_content_p2">
          Virtual Crypto and Investment Bank of Kemet Provides you an
          opportunity to benefit from Digital Currency, Commodity and
          Technology. Also We offer a unique and powerful AI Trading Bot that
          explores current/emerging trends in the market to help you achieve
          financial freedom. In addition benefited through investment bank
          activities.
        </p>
      </div>

      <OurServices />

      <UserInfo />

      <AboutUs />

      <ContactUs />

      <div className="home_container__footer">
        <div className="home_container__footer__left">
          <p className="home_container__footer__left__title typography_content_p1 typography_bold">
            Kemet
          </p>
          <p className="home_container__footer__left__info typography_content_p3 typography_light">
            Kemet a “Black Land” Africans Civilization where there was no one in
            the world.
          </p>
        </div>
        <div className="home_container__footer__right">
          <p className="home_container__footer__right__title typography_content_p1 typography_bold">
            Designed By
          </p>
          <p className="home_container__footer__right__info typography_content_p3 typography_light">
            Yonatan
          </p>
          <p className="home_container__footer__right__info typography_content_p3 typography_light">
            yonatan.dawit.dev@gmail.com
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
