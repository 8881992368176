import "./index.scss";
import "./phoneStyle.scss";
import clipboardIcon from "../../assets/icons/clipboard.svg";
import bitcoinLogo from "../../assets/images/bitcoin-logo.png";
import paypalLogo from "../../assets/images/paypal.png";
import topWave from "../../assets/images/top-wave.svg";
import altTopWave from "../../assets/images/alt-top-wave.svg";
import bottomWave from "../../assets/images/bottom-wave.svg";

const OurServices = () => {
  return (
    <div className="service_container" id="services">
      <img src={topWave} alt="" className="service_container__wave" />
      <div className="service_container__payment_partners">
        <p className="service_container__payment_partners__title typography_h0 typography_bold">
          Our Payment Partners
        </p>

        <div className="service_container__payment_partners__partner">
          <img
            src={bitcoinLogo}
            alt="Bitcoin Logo"
            className="service_container__payment_partners__partner__logo"
          />
          <div className="service_container__payment_partners__partner__details bitcoin card">
            <p className="service_container__payment_partners__partner__details__title">
              bc1q536cpt29509lwtah3cr7tua0d94hqteg95mars
            </p>

            <div
              className="service_container__payment_partners__partner__details__copy"
              onClick={() =>
                navigator.clipboard.writeText(
                  "bc1q536cpt29509lwtah3cr7tua0d94hqteg95mars"
                )
              }
            >
              <img src={clipboardIcon} alt="Copy Icon" />
              <p>COPY</p>
            </div>
          </div>
        </div>

        <div
          className="service_container__payment_partners__partner"
          onClick={() =>
            navigator.clipboard.writeText("investmentbankofkemet@gmail.com")
          }
        >
          <img
            src={paypalLogo}
            alt="Bitcoin Logo"
            className="service_container__payment_partners__partner__logo"
          />

          <div className="service_container__payment_partners__partner__details card">
            <p className="service_container__payment_partners__partner__details__title">
              investmentbankofkemet@gmail.com
            </p>

            <div className="service_container__payment_partners__partner__details__copy">
              <img src={clipboardIcon} alt="Copy Icon" />
              <p>COPY</p>
            </div>
          </div>
        </div>
      </div>

      <div className="service_container__investment_plans card">
        <p className="service_container__investment_plans__title typography_h0">
          Investment Plans in USD
        </p>
        <p className="service_container__investment_plans__description typography_content_p4">
          Use our Bitcoin and PayPal addresses to send your selected investment
          package by adding 10% bank service charge
        </p>

        <div className="service_container__investment_plans__container">
          <div className="service_container__investment_plans__container__group">
            <p>50</p>
            <p>100</p>
            <p>150</p>
            <p>200</p>
            <p>250</p>
            <p>300</p>
          </div>

          <div className="service_container__investment_plans__container__group">
            <p>350</p>
            <p>400</p>
            <p>450</p>
            <p>500</p>
            <p>550</p>
            <p>600</p>
          </div>
          <div className="service_container__investment_plans__container__group">
            <p>650</p>
            <p>700</p>
            <p>750</p>
            <p>800</p>
            <p>850</p>
            <p>900</p>
          </div>
          <div className="service_container__investment_plans__container__group">
            <p>1000</p>
            <p>2000</p>
            <p>3000</p>
            <p>4000</p>
            <p>5000</p>
            <p>6000</p>
          </div>
          <div className="service_container__investment_plans__container__group">
            <p>7000</p>
            <p>8000</p>
            <p>9000</p>
            <p>10000</p>
            <p>11000</p>
            <p>12000</p>
          </div>
        </div>
      </div>

      <div className="service_container__business_development card">
        <p className="service_container__business_development__title typography_h0">
          Business Development Service Program
        </p>

        <div className="service_container__business_development__group">
          <p className="service_container__business_development__group__title typography_content_p3">
            Direct Service Retail Commission
          </p>
          <p className="service_container__business_development__group__value  typography_content_p3 typography_bold">
            5%
          </p>
        </div>

        <div className="service_container__business_development__group">
          <p className="service_container__business_development__group__title typography_content_p3">
            Indirect Service Retail Commission
          </p>
          <p className="service_container__business_development__group__value  typography_content_p3 typography_bold">
            1% up to 3 legs
          </p>
        </div>

        <div className="service_container__business_development__group">
          <p className="service_container__business_development__group__title typography_content_p3">
            Net profit dividend every year from investment date
          </p>
          <p className="service_container__business_development__group__value  typography_content_p3 typography_bold">
            100%
          </p>
        </div>

        <div className="service_container__business_development__group">
          <p className="service_container__business_development__group__title typography_content_p3">
            Rank award as per sales volume from
          </p>
          <p className="service_container__business_development__group__value  typography_content_p3 typography_bold">
            2g up to 500g gold%
          </p>
        </div>
      </div>

      <img src={bottomWave} alt="" className="service_container__wave" />
      <img src={altTopWave} alt="" className="service_container__wave" />
    </div>
  );
};

export default OurServices;
