import * as types from "../actionTypes/register";
import initialState from "../initialState";

const register = (state = initialState.register, { type, payload }) => {
  switch (type) {
    case types.REGISTRATION_START:
      return { loading: true, error: null };
    case types.REGISTRATION_SUCCESS:
      return { loading: false, error: null };
    case types.REGISTRATION_ERROR:
      return { loading: false, error: payload.error };
    default:
      return state;
  }
};

export default register;
